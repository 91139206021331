<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' },
        { validator: inputValidator, trigger: 'blur' },
        { validator: inputValidator, trigger: 'change' },
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>

      <el-input
        ref="input"
        v-model="localValue"
        v-mask="vmask"
        :name="name"
        :size="size"
        :placeholder="placeholder || $locale.main.placeholder.string_field"
        :readonly="readonly"
        @input="$event => onInput($event)"
        @keyup.enter.native="$event => onEnter()"
      ></el-input>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import maskMixin from '@/mixins/maskMixin.js'
import InputLabel from '@/mixins/inputLabel.js'

export default {
  name: 'a-string',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, registryMixin, maskMixin, InputLabel],
  props: {
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    label: {
      type: String,
      description: 'Название'
    },
    name: {
      type: String,
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    defaultValue: {
      type: String,
      description: 'Значение по умолчанию'
    },
    mask: {
      type: String,
      frozen: true
    },
    inputType: {
      type: Object,
      editor: 'InputType',
      default: () => ({
        isMaskGuide: true,
        isSaveMasked: true
      })
    },
    placeholder: {
      type: String,
      description: 'Плейсхолдер'
    },
    tooltip: {
      type: String,
      description: 'Подсказка'
    },
    emitOnEnter: {
      type: Boolean,
      description: 'Изменять по Enter'
    }
  },
  data () {
    return {
      localValue: this.value || this.defaultValue
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },
    value (value) {
      this.localValue = value
      this.onInput(this.localValue)
    }
  },
  async mounted () {
    if (this.mask) {
      this.$emit('change-property', { name: 'inputType', value: { mode: 'maskSymbolsVmask', maskSymbolsVmask: this.mask, isAdvanced: true } })
      this.$emit('change-property', { name: 'mask', value: null })
    }

    if (this.defaultValue) {
      let value = this.defaultValue
      if (value === '{{user_id}}') {
        value = this.$store.getters['Authorization/userId']
      }
      if (value === '{{role_id}}') {
        value = this.$store.getters['Authorization/roleId']
      }
      if (/user.attr_[0-9]+_/i.test(value)) {
        value = await this.$store.getters['Authorization/userAttributeData'](value.match(/attr_[0-9]+_/gi)[0])
      }
      if (Array.isArray(value)) {
        let temp = []
        value.forEach((item) => {
          temp.push(item.name || item)
        })
        this.localValue = temp.join(', ')
      } else {
        this.localValue = value
      }
      this.$emit('input', this.localValue)
    }
  },
  methods: {
    onInput (event) {
      // Вызывается из el-input с заполненным event и из maskMixin.js
      let valueToEmit = event

      if (this.imask) {
        this.localValue = event
        valueToEmit = (this.inputType.isSaveMasked) ? this.imask.value : this.imask.unmaskedValue
      }

      if (!this.emitOnEnter) {
        this.$emit('input', valueToEmit)
      }
    },
    onEnter () {
      let valueToEmit = this.localValue

      if (this.imask) {
        valueToEmit = (this.inputType.isSaveMasked) ? this.imask.value : this.imask.unmaskedValue
      }

      if (this.emitOnEnter) {
        this.$emit('input', valueToEmit)
      }
    },
    inputValidator (rule, value, callback) {
      // Работает только при заполненном "Атрибут"
      let isValid = true
      let message = ''

      if (this.inputType && (this.inputType.type || this.inputType.isAdvanced /* Выбран "Тип поля" или выбран "Расширенный" */)) {
        // Валидатор регулярного выражения
        if (['validatorRegex', 'validatorTemplateRegex'].includes(this.inputType.mode) && this.inputType.validatorRegex) {
          if (!RegExp(this.inputType.validatorRegex, this.inputType.validatorRegexFlags || '').test(this.localValue) && (this.localValue || '').length > 0) {
            console.log(this.label, this.name)
            isValid = false
            message += 'Некорректное значение'
          }
        }

        // Валидатор маски
        if (this.inputType.isValidateMask && this.imask && this.imask.isValid === false) {
          isValid = false
          message += 'Значение не совпадает с маской'
        }
      }

      return (!isValid) ? callback(message) : callback()
    }
  }
}
</script>

<style scoped>
* /deep/ .el-form-item__error {
  /* position: relative; */
}
</style>
